<template>
<div>
  <div v-if="!loading && permission.read">
  <b-container class="my-4 py-4 border-bottom">
    <b-row v-if="annotation.regulation">
      <b-col>
        <div><strong><b-link :to="{ name: 'RegDecompWorkspace', params: { id: annotation.regulation.id }}">{{annotation.regulation.name_short}} | {{annotation.regulation.id}}</b-link></strong></div>
        <div>{{annotation.regulation.name_long}}</div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span style="font-size: 1.25em" v-if="annotation.obligationtype"><b-badge :variant="badgeVariant">{{annotation.obligationtype.name}}</b-badge></span> citation: {{annotation.citation}} | id: {{annotation.id}}
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <div v-if="annotation.topic"><strong>{{annotation.topic}}</strong></div>
        <div>
          <span v-if="savingImport">
            <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
          </span>
          <annotation-history-modal :annotation="annotation"></annotation-history-modal>
          <strong style="font-size: 1.25em">{{annotation.name}}</strong>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.active.length > 0">
      <b-col>
        <div v-for="row in annotation.active" :key="'active'+row.id">
          This <strong>{{row.relationship.name}}</strong> {{row.obligation.name}} in <b-link :to="{ name: 'RegDecompWorkspace', params: { id: row.obligation.regulation.id }}" class="mr-2">{{row.obligation.regulation.name_short}}</b-link>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.passive.length > 0">
      <b-col>
        <div v-for="row in annotation.passive" :key="'passive'+row.id">
          This <strong>{{row.relationship.passive}}</strong> {{row.obligation.name}} in <b-link :to="{ name: 'RegDecompWorkspace', params: { id: row.obligation.regulation.id }}" class="mr-2">{{row.obligation.regulation.name_short}}</b-link>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.text">
      <b-col>
        <div><strong>regulatory text</strong></div>
        <div><new-lines :content="annotation.text"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.description">
      <b-col>
        <div><strong>description</strong></div>
        <div><new-lines :content="annotation.description"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.context">
      <b-col>
        <div><strong>context</strong></div>
        <div><new-lines :content="annotation.context"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.standard">
      <b-col>
        <div><strong>minimum standard</strong></div>
        <div><new-lines :content="annotation.standard"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.lagging">
      <b-col>
        <div><strong>lagging market practice</strong></div>
        <div><new-lines :content="annotation.lagging"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.market_practice">
      <b-col>
        <div><strong>leading market practice</strong></div>
        <div><new-lines :content="annotation.market_practice"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.date_effective">
      <b-col>
        <div><strong>effective date</strong></div>
        <div>{{moment(annotation.date_effective).format('YYYY-MM-DD')}}</div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <div>{{annotation.updated_by}} - {{moment(annotation.updated_at).format('YYYY-MM-DD HH:mm')}}</div>
      </b-col>
      <b-col>
        <div><strong>status:</strong> {{step.name}}</div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
      </b-col>
      <b-col>
        <div v-if="editAlwaysOn">
          <span v-if="!savingStep">
            <b-button class="mr-4" v-if="step.downStep && permissionWorkflowDown" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
            <b-button v-if="step.upStep && permissionWorkflowUp" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
          </span>
          <span v-if="savingStep">
            <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="showEntities" class="mb-4">
      <b-col>
        <div>
          <select-list-props
            :editable="false"
            :editAlwaysOn="editAlwaysOn"
            :labelLinkingField="'entity_id'"
            :linkingModel="'entityobligation'"
            :name="'Applicable entities'"
            :parentId="annotation.id"
            :parentLinkingField="'obligation_id'"
            :valuesProp="annotation.entities"
            :verticesProp="entities"
          ></select-list-props>
        </div>
      </b-col>
      <b-col>
        <div>
          <select-list-props
            :editable="false"
            :editAlwaysOn="editAlwaysOn"
            :labelLinkingField="'area_id'"
            :linkingModel="'areaobligation'"
            :name="'Applicable functions'"
            :parentId="annotation.id"
            :parentLinkingField="'obligation_id'"
            :valuesProp="annotation.areas"
            :verticesProp="areas"
          ></select-list-props>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="showRequirements">
      <b-col>
        <div>
          <select-list-props
            :editable="false"
            :editAlwaysOn="editAlwaysOn"
            :labelLinkingField="'requirement_id'"
            :linkingModel="'obligationrequirement'"
            :name="'Applicable requirements'"
            :parentId="annotation.id"
            :parentLinkingField="'obligation_id'"
            :valuesProp="annotation.requirements"
            :verticesProp="requirements"
          ></select-list-props>
        </div>
      </b-col>
      <b-col>
        <div>
          <select-list-props
            :editable="false"
            :editAlwaysOn="editAlwaysOn"
            :labelLinkingField="'riskcomponent_id'"
            :linkingModel="'obligationriskcomponent'"
            :name="'Risk framework components'"
            :parentId="annotation.id"
            :parentLinkingField="'obligation_id'"
            :valuesProp="annotation.riskcomponents"
            :verticesProp="riskcomponents"
          ></select-list-props>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="editAlwaysOn">
      <b-col>
        <div v-if="!savingComment">
          <b-form-textarea
            v-model="commentNew"
            placeholder="add comment"
            :rows="2"
            class="mb-2">
          </b-form-textarea>
          <b-button v-if="commentNew" class="mr-2" variant="primary" size="sm" v-on:click="commentAdd">send</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="comments.length > 0">
      <b-col>
        <div v-for="comment in comments" :key="'comment'+comment.id">
          <div>
            <small>{{comment.username}}: at {{moment(comment.created_at).format('HH:mm')}} on {{moment(comment.created_at).format('YYYY-MM-DD')}}</small>
            <b-button v-if="editAlwaysOn" class="mx-2" variant="outline-primary" size="sm" v-on:click="commentDelete(comment.id)">delete</b-button>
          </div>
          <div class="mb-2">
            {{comment.content}}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="annotation.controls.length > 0" class="my-4">
      <b-col>
        <div><strong>Controls</strong></div>
        <div v-for="control in annotation.controls" :key="'control'+control.id">
          <div class="mb-2">
            <b-link class="mr-4" :to="{ name: 'Control', params: { id: control.id }}">{{control.name}}</b-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
  </div>
  <div>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permission.read">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
  </b-container>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import workflow from '@/workflows/annotation'
import AnnotationHistoryModal from '@/components/AnnotationHistoryModal.vue'
import SelectListProps from '@/components/sql/SelectListProps.vue'
import NewLines from '@/components/NewLines.vue'

export default {
  name: 'ObligationItem',
  components: {
    AnnotationHistoryModal,
    NewLines,
    SelectListProps
  },
  computed: {
    badgeVariant: function () {
      let res = 'primary'
      if (this.annotation.obligationtype) {
        if (this.annotation.obligationtype.name.toLowerCase() === 'obligation') {
          res = 'danger'
        }
        if (this.annotation.obligationtype.name.toLowerCase() === 'entity') {
          res = 'success'
        }
        if (this.annotation.obligationtype.name.toLowerCase() === 'context') {
          res = 'info'
        }
      }
      return res
    },
    permissionWorkflowDown: function () {
      let result = false
      if (this.step.id > 7 && ac.can(this.user.acgroups).readAny('obligationWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id <= 7 && ac.can(this.user.acgroups).updateAny('obligation').granted) {
        result = true
      }
      return result
    },
    permissionWorkflowUp: function () {
      let result = false
      if (this.step.id >= 7 && ac.can(this.user.acgroups).readAny('obligationWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id < 7 && ac.can(this.user.acgroups).updateAny('obligation').granted) {
        result = true
      }
      return result
    },
    showEntities: function () {
      let show = false
      if (this.annotation.obligationtype) {
        if (this.annotation.obligationtype.name.toLowerCase() === 'obligation') {
          show = true
        }
      }
      return show
    },
    showRequirements: function () {
      let show = false
      if (this.annotation.obligationtype) {
        if (this.annotation.obligationtype.name.toLowerCase() === 'obligation') {
          show = true
        }
      }
      return show
    },
    step: function () {
      const step = workflow.find(x => x.id === this.annotation.status_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.read = ac.can(this.user.acgroups).readAny('obligation').granted
    if (this.permission.read) {
      await this.load()
    }
  },
  data () {
    return {
      annotation: {},
      areas: [],
      commentNew: null,
      comments: [],
      editAlwaysOn: true,
      moment: moment,
      entities: [],
      imported: false,
      loading: true,
      permission: {},
      regulation: {},
      requirements: [],
      riskcomponents: [],
      savingComment: false,
      savingImport: false,
      savingStep: false,
      typeOptions: [],
      typeFilterOptions: [],
      workflow: workflow,
      workflowStatusOptions: []
    }
  },
  methods: {
    load: async function () {
      try {
        this.$stat.log({ page: 'obligation', action: 'open obligation' })
        this.regulation = null
        this.annotation = []
        const db = await this.$Amplify.API.get('cosmos', `/obligations/obligation/${this.$route.params.id}`)
        this.annotation = db.obligation
        this.entities = db.entities
        this.areas = db.areas
        this.requirements = db.requirements
        this.riskcomponents = db.riskcomponents
        const types = db.types
        const typeOptions = []
        typeOptions.push({ value: null, text: 'Select type' })
        types.forEach(type => {
          typeOptions.push({ value: type.id, text: type.name })
          this.typeFilterOptions.push({ value: type.id, text: type.name })
        })
        this.typeOptions = typeOptions
        _.each(workflow, x => {
          this.workflowStatusOptions.push({ text: x.name, value: x.id })
        })
        if (this.annotation.comments) {
          this.comments = this.annotation.comments
        }
        this.loading = false
      } catch (e) {
        console.log(e)
        this.$logger.warn('getting reulation error', e)
      }
    },
    commentAdd: async function () {
      this.savingComment = true
      try {
        // let response = await this.$http(config)
        const params = {
          body: {
            obligation_id: this.annotation.id,
            regulation_id: this.regulation.id,
            content: this.commentNew,
            username: this.user.username
          }
        }
        const result = await this.$Amplify.API.put('cosmos', '/standard/obligationscomment', params)
        params.body.id = result.id
        this.comments.unshift(params.body)
        const paramsLog = {
          body: {
            obligation_id: this.annotation.id,
            regulation_id: this.regulation.id,
            action: 'added comment:' + this.commentNew,
            username: this.user.username
          }
        }
        this.commentNew = null
        this.savingComment = false
        await this.$Amplify.API.put('cosmos', '/standard/obligationslog', paramsLog)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    },
    commentDelete: async function (id) {
      this.savingComment = true
      try {
        await this.$Amplify.API.del('cosmos', `/standard/obligationscomment/${id}`)
        const paramsLog = {
          body: {
            obligation_id: this.annotation.id,
            regulation_id: this.regulation.id,
            action: 'delete comment',
            username: this.user.username
          }
        }
        const temp = this.comments
        _.remove(temp, x => {
          return x.id === id
        })
        this.comments = temp
        this.savingComment = false
        await this.$Amplify.API.put('cosmos', '/standard/obligationslog', paramsLog)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    },
    importObligation: async function () {
      try {
        this.savingImport = true
        const params = {
          body: {
            annotation: this.annotation,
            regulationId: this.regulation.id,
            regulationGraphNode: this.regulationGraphNode,
            user: this.user
          }
        }
        await this.$Amplify.API.put('cosmos', `/obligation/import`, params)
        this.savingImport = false
        this.imported = true
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        // let response = await this.$http(config)
        const params = {
          body: {
            status_id: id
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/obligation/${this.annotation.id}`, params)
        const paramsLog = {
          body: {
            obligation_id: this.annotation.id,
            regulation_id: this.regulation.id,
            action: 'moved workflow step to ' + this.step.name,
            username: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', '/standard/obligationslog', paramsLog)
        this.annotation.status_id = id
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  }
}
</script>

<style scoped>
</style>
